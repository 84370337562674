import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/home/home'),
  },
  {
    path: '/noPermissions',
    name: 'noPermissions',
    component: () => import('@/views/noPermissions/noPermissions'),
  },
  {
    path: '/web',
    name: 'web',
    component: () => import('@/views/home/web'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/account/login'),
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/account/register'),
  },
  {
    path: '/market',
    name: 'market',
    component: () => import('@/views/market/market'),
  },
  {
    path: '/transaction/index',
    name: 'transaction',
    component: () => import('@/views/transaction/index'),
  },
  {
    path: '/transaction/spot',
    name: 'spot',
    component: () => import('@/views/transaction/spot'),
  },
  {
    path: '/transaction/klineChart',
    name: 'klineChart',
    component: () => import('@/views/transaction/klineChart'),
  },
  {
    path: '/currency',
    name: 'currency',
    component: () => import('@/views/currency/currency'),
  },
  {
    path: '/currency/order',
    name: 'currency',
    component: () => import('@/views/currency/order'),
  },
  {
    path: '/assets',
    name: 'assets',
    component: () => import('@/views/assets/assets'),
  },
  {
    path: '/recharge',
    name: 'recharge',
    component: () => import('@/views/assets/recharge'),
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/assets/withdraw'),
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('@/views/my/my'),
  },
  {
    path: '/identity',
    name: 'identity',
    component: () => import('@/views/my/identity'),
  },
  {
    path: '/order',
    name: 'order',
    component: () => import('@/views/my/order'),
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/my/history'),
  },
  {
    path: '/address',
    name: 'address',
    component: () => import('@/views/my/address'),
  },
  {
    path: '/addressList',
    name: 'address',
    component: () => import('@/views/my/addressList'),
  },
  {
    path: '/address/add',
    name: 'address/add',
    component: () => import('@/views/my/add_address'),
  },
  {
    path: '/share',
    name: 'share',
    component: () => import('@/views/my/share'),
  },
  {
    path: '/language',
    name: 'language',
    component: () => import('@/views/my/language'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => import('@/views/my/help'),
  },
  {
    path: '/accountPsd',
    name: 'accountPsd',
    component: () => import('@/views/my/accountPsd'),
  },
  {
    path: '/withdrawPsd',
    name: 'withdrawPsd',
    component: () => import('@/views/my/withdrawPsd'),
  },
  {
    path: '/information',
    name: 'information',
    component: () => import('@/views/my/information'),
  },
  {
    path: '/details',
    name: 'details',
    component: () => import('@/views/my/details'),
  },
  {
    path: '/lockming',
    name: 'lockming',
    component: () => import('@/views/lockming/lockming'),
  },
  {
    path: '/lock-confirm',
    name: 'lock-confirm',
    component: () => import('@/views/lockming/confirm'),
  },
  {
    path: '/lock-order',
    name: 'lock-order',
    component: () => import('@/views/lockming/order'),
  },
  {
    path: '/subscribe',
    name: 'subscribe',
    component: () => import('@/views/subscribe/subscribe'),
  },
  {
    path: '/subs-confirm',
    name: 'subs-confirm',
    component: () => import('@/views/subscribe/confirm'),
  },
  {
    path: '/subs-order',
    name: 'subs-order',
    component: () => import('@/views/subscribe/order'),
  },


  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/my/bank'),
  },
  {
    path: '/bankList',
    name: 'bank',
    component: () => import('@/views/my/bankList'),
  },
  {
    path: '/bank/add',
    name: 'bank/add',
    component: () => import('@/views/my/add_bank'),
  },
]
const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location) {

  return originalPush.call(this, location).catch(err => err)

}


const router = new VueRouter({
  routes
})

export default router
