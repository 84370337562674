export default {
    foot: {
        nav1: "Startseite",
        nav2: "Markt",
        nav3: "Transaktion",
        nav4: "Währung",
        nav5: "Vermögenswert",
    },
    userLevel: {
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "Normale Benutzer",
        level1: "Silbernutzer",
        level2: "Goldnutzer",
        level3: "Platinbenutzer",
        level4: "Diamantanwender",
        level5: "Wang Zhe Benutzer",
        level6: "Ultimativer Benutzer",
    },
    currenctType: {
        title: "Bitte wählen Sie eine Währung",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dollar',
        currenctType15: 'Hongkong-Dollar',
        currenctType16: 'Taiwan Währung',
        currenctType17: 'Myr',
        currenctType18: 'Neue Währung',
        currenctType19: 'Japanischer Yen',
        currenctType20: 'Euro',
        currenctType21: 'AUD',
        currenctType22: 'Indonesische Rupiah',
        currenctType23: 'Pfund',
        currenctType24: 'Thai Baht',
        currenctType25: 'Cad',
        currenctType26: 'Vietnamesischer Dong',
        currenctType27: 'Koreanischer Won',
        currenctType28: 'Macau Pataca',
    },
    account: {
        login: "Anmeldung",
        label_eMail: "Mailbox",
        place_eMail: "Bitte geben Sie Ihre E-Mail-Adresse ein",
        tip_eMail: "Bitte geben Sie Ihre E-Mail-Adresse korrekt ein",
        label_password: "Kennwort",
        place_password: "Bitte geben Sie Ihr Passwort ein",
        tip_password: "Bitte geben Sie das richtige Passwort ein",
        no_account: "Sie haben noch kein Konto?" ,
        register: "Registrieren",
        send: "Überprüfungscode senden",
        label_code: "E-Mail-Verifizierungscode",
        place_code: "E-Mail-Verifizierungscode",
        tip_code: "Bitte geben Sie Ihren E-Mail-Verifizierungscode ein",
    },
    assets: {
        title: "Vermögen",
        total_assets: "Gesamtvermögen",
        aufladen: "aufladen",
        withdraw: "Abheben",
        available: "verfügbar",
        einfrieren: "eingefroren",
        total: "Gesamt",
        label_bank: "Währung",
        place_bank: "Bitte wählen Sie eine Währung",
        tip_bank: "Bitte wählen Sie eine Währung",
        label_amount: "Betrag",
        place_amount: "Bitte geben Sie den Betrag ein",
        tip_amount: "Bitte geben Sie den richtigen Betrag ein",
        label_password: "Passwort",
        place_password: "Bitte geben Sie Ihr Passwort ein",
        tip_password: "Bitte geben Sie das richtige Passwort ein",
        label_balance: "Verfügbare USDT",
        all: "Alle Abhebungen",
        no_password: "Bitte geben Sie dies zur Sicherheit Ihres Geldes ein",
        withdraw_psd: "Passwort für Abhebungen",
        label_wallet: "Geldbörse",
        place_wallet: "Bitte wählen Sie ein Wallet",
        tip_wallet: "Bitte wählen Sie ein Wallet",
    },
    currency: {
        position: "Position",
        drawer_title: "Währung_Handel",
        jetzt: "aktuell",
        offen: "Offen",
        volumen: "Volumen",
        height: "Höchste",
        niedrig: "Niedrigster",
        buy: "Kauf Long",
        verkaufen: "Leerverkauf",
        name: "Name",
        richtung: "Richtung",
        crm_order: "Auftrag bestätigen",
        now_price: "aktueller_preis",
        type_time: "Lieferzeit (Linksschieber für höhere Erträge)",
        second: "Sekunden",
        buy_amount: "Kaufbetrag",
        verfügbar: "verfügbar",
        service_fee: "Provision",
        min_number: "Ab ",
        errorTip1: "Falsche Eingabe",
        errorTip2: "Unzureichendes verfügbares Guthaben",
        auto_jump: "Automatisch zur Bestellung springen nach",
        keep: "Weiter Bestellungen aufgeben",
        revenue: "Erwartete Erträge",
    },
    home: {
        quick: "Schnelle Münzspülung",
        unterstützung: "Unterstützung",
        nav1: "Teilen",
        nav2: "Brieftasche",
        nav3: "Info",
        nav4: "Anleitungen",
        nav5: "Kundendienst",
        nav6: "Bergbau",
        nav7: "Über uns",
        nav8: "Neue Münzabonnements",
        anstieg: "Aufwärtsliste",
        volumen: "Volumen",
        table_name: "Name",
        table_lastPrice: "Letzter Preis",
        table_increase: "Aufwärts/Abwärts",
        table_volume: "Umsatz",
    },
    lockming: {
        profit: "Jüngste (tägliche Einnahmen)",
        cycle: "Finanzzyklus",
        limit: "Einzel-Limit",
        dividend_time: "Zeitpunkt der Dividendenausschüttung",
        every_day: "täglich",
        funds: "Treuhandfonds",
        back: "Rendite bei Fälligkeit",
        ransom: "Vorzeitige Rückzahlung",
        label_amount: "Verpfändungsbetrag",
        place_amount: "Bitte eingeben,,,",
        available: "verfügbar",
        expect_income: "Erwartetes Einkommen",
        title: "Bergbau unter Verschluss",
        tip: "Bergbau für Einkommen",
        label_today_income: "Geschätztes Einkommen heute",
        label_total_income: "Kumuliertes Einkommen",
        label_order: "Aufträge im Treuhandkonto",
        label_number: "Einzelner Betrag",
        daily_return: "Täglicher Ertrag",
        subscribe: "abonnieren",
        position: "Position",
        label_state: "Status",
        subscribe_time: "Abonnement-Zeit",
        expiration_time: "Verfallszeit",
        income: "Einkommen",
        errorTip1: "Bitte geben Sie den Betrag ein",
        tip_tit: "Bist du sicher, dass du früher aussteigen willst?",
    },
    market: {
        spots: "Auf Lager verfügbar",
        contract: "Verträge",
        currency_trans: "Devisenhandel",
        increase: "Top-Gewinner",
        volume: "Umsatz",
        label_name: "Name",
        label_lastPrice: "Letzter Preis",
        table_increase: "Nach oben oder unten",
        table_volume: "Umsatz",
    },
    my: {
        details: "Details",
        help: "Hilfezentrum",
        information: "Informationen",
        language: "Sprachumschaltung",
        password: {
            account_title: "Passwort ändern",
            withdraw_title: "Passwort ändern",
            label_oldPsd: "Altes Kennwort",
            place_oldPsd: "Bitte geben Sie Ihr altes Passwort ein",
            label_newPsd: "Neues Kennwort",
            place_newPsd: "Bitte geben Sie ein neues Passwort ein",
            label_crmPsd: "Bestätigen Sie Ihr Passwort",
            place_crmPsd: "Bitte geben Sie erneut ein neues Passwort ein",
            withdraw_tip: "Wenn Sie kein Abhebungspasswort festgelegt haben, lassen Sie es bitte frei",
            password_tips: "Das Passwort muss eine Kombination aus 6-16 Buchstaben und Zahlen sein",
        },
        address: {
            title: "Adresse der Brieftasche",
            account_number: "Kontonummer",
            add_btn: "add_address",
            label_type: "Typ auswählen",
            place_type: "Bitte Typ auswählen",
            label_number: "Kontonummer",
            place_number: "Bitte geben Sie die Kontonummer ein",
            wallet_address: 'Wallet-Adresse',
            bank_name: 'Name der Bank',
            bank_address: "Bankadresse",
            name: "Name",
            payee_address: 'Empfängeradresse',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Zweigname",
            branch_code: "Zweigstellencode",
            bank_code: "Bankleitzahl",
            phone: "Mobiltelefonnummer",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Bitte Inhalt eingeben",
            bank_user: "Karteninhaber",
            bank_branch: "Zweig",
            bank_number: "Bankkartennummer",
            place_bank_name: "Bitte geben Sie den Banknamen ein",
            place_bank_user: "Bitte geben Sie den Namen des Karteninhabers ein",
            place_bank_branch: "Bitte geben Sie den Namen der Filiale ein",
            place_bank_number: "Bitte geben Sie Ihre Bankkartennummer ein",
            place_bank_isfc: "Bitte geben Sie ISFC ein",
        },
        history: {
            title: "Geschichte",
            recharge_record: "Auflade-Datensatz",
            withdraw_record: "Rücknahme-Datensatz",
            withdraw: "Rücknahme",
            number: "Zahl",
            currency: "Währung",
            state: "Status",
            state0: "Ausstehend",
            state1: "Erledigt",
            state2: "Abgelehnt",
        },
        identity: {
            title: "Zertifizierung",
            label_country: "Wählen Sie eine Nationalität",
            place_country: "Bitte wählen Sie eine Nationalität",
            label_name: "Name",
            place_name: "Bitte geben Sie Ihren Namen ein",
            label_id: "Ausweisnummer",
            place_id: "Bitte geben Sie die Nummer Ihres Personalausweises/Führerscheins/Reisepasses ein",
        },
        my: {
            liste1: "Authentifizierung",
            liste2: "Auftragsverwaltung",
            liste3: "Geldgeschichte",
            liste4: "Wallet-Adresse",
            liste5: "Sprache wechseln",
            liste6: "Freunde einladen",
            liste7: "Kundenservice",
            liste8: "Anleitungen",
            liste9: "Passwort ändern",
            logout: "Abmelden",
        },
        order: {
            title: "Position",
            direction: "Richtung",
            sell_price: "Verkaufskurs",
            number: "Menge",
            type: "Lieferzeit",
            buy_price: "Geldkurs",
            profit: "Gewinn/Verlust",
            sell_time: "verkauf_zeit",
        },
        share: {
            title: "Freunde einladen",
            save_ewm: "QR-Code speichern",
            invite_link: "Link einladen",
            copy_address: "Adresse kopieren",
            invite_code: "Registrierungscode",
            copy_code: "Inhalt kopieren",
            tip: "Registrieren Sie sich über den obigen QR-Code oder Link",
        },
    },
    transaction: {
        spots: "Spot",
        contract: "Vertrag",
        currency_trans: "währung_transaktionen",
        exchange: "flash",
        sustainable: "perpetual",
        warehouse: "Position-für-Position",
        jump_lever: "Sprung-Hebel",
        sell: "verkaufen",
        buy: "kaufen",
        available: "verfügbar",
        check_price: "Limit_Preis",
        market_price: "markt_preis",
        number: "Menge",
        max: "max",
        bail: "Spanne",
        buy_long: "Long kaufen",
        sell_short: "Leerverkauf",
        last_price: "Letzter Preis",
        now_entrust: "aktuelles_entrust",
        now_position: "Aktuelle Position",
        table_amount: "Abhebungsbetrag",
        is_reduce: "Nur Position reduzieren",
        shi: "Ja",
        fou: "Nein",
        position_number: "Anzahl der gehaltenen Positionen",
        is_profit: "Realisierter Gewinn",
        wei: "noch nicht",
        open_price: "Offener Preis",
        mark_price: "Markenkurs",
        flat_price: "Starker_Level-Preis",
        trim_lever: "Hebelwirkung anpassen",
        flat_store: "Position schließen",
        trim_bail: "Marge anpassen",
        reduce_bail: "Marge reduzieren",
        now_bail: "Aktuelle Positionsspanne",
        max_add: "Maximale Erhöhung",
        refer_flat: "Angepasster referenzstarker Pauschalpreis",
        expect_profit: "Erwarteter Gewinn/Verlust",
    },
    common: {
        confirm: "Bestätigen",
        all: "Alle",
        close: "Abbrechen",
        copy_success: "Kopie erfolgreich!" ,
        copy_error: "Kopie fehlgeschlagen!" ,
        place_input: "Bitte Inhalt eingeben",
        more: "mehr",
        main: "Haup",
        sub: "Unte",
        success: "Erfolg!",
        cancel_tip: "Sind Sie sicher, dass Sie abbrechen wollen?" ,
        notice: "Benachrichtigung",
        logout_tip: "Bist du sicher, dass du aussteigst?",
        tip: "prompt",
        day: "Tag"
    },
    params: {
        transType0: "Spot",
        transType1: "Vertrag",
        directionType0: "Kaufen",
        directionType1: "Verkaufen",
        lockStateType0: "Laufend",
        lockStateType1: "Beendet",
        lockStateType2: "Abgebrochen",
    },
    city: {
		albania: "Albanien",
		algeria: "Algerien",
		argentina: "Argentinien",
		armenia: "Armenien",
		australia: "Australien",
		pakistan: "Pakistan",
		austria: "Österreich",
		bahrain: "Bahrain",
		belgium: "Belgien",
		bosnia_and_Herzegovina: "Bosnien und Herzegowina",
		brazil: "Brasilien",
		brunei: "Brunei",
		bulgaria: "Bulgarien",
		cambodia: "Kambodscha",
		canada: "Kanada",
		cameroon: "Kamerun",
		chile: "Chile",
		colombia: "Kolumbien",
		costa_Rica: "Costa Rica",
		croatia: "Kroatien",
		cyprus: "Zypern",
		czech_Republic: "Tschechische Republik",
		denmark: "Dänemark",
		dominican_Republic: "Dominikanische Republik",
		egypt: "Ägypten",
		estonia: "Estland",
		ethiopia: "Äthiopien",
		finland: "Finnland",
		france: "Frankreich",
		georgia: "Georgien",
		germany: "Deutschland",
		ghana: "Ghana",
		greece: "Griechenland",
		guyana: "Guyana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Ungarn",
		iceland: "Island",
		ireland: "Irland",
		italy: "Italien",
		india: "Indien",
		indonesia: "Indonesien",
		israel: "Israel",
		iran: "Iran",
		iraq: "Irak",
		japan: "Japan",
		kazakstan: "Kasachstan",
		kenya: "Kenia",
		korea: "Korea",
		kuwait: "Kuwait",
		kyrgyzstan: "Kirgisistan",
		laos: "Laos",
		latvia: "Lettland",
		lithuania: "Litauen",
		luxembourg: "Luxemburg",
		macao_China: "Macau, China",
		macedonia: "Mazedonien",
		malaysia: "Malaysia",
		malta: "Malta",
		mexico: "Mexiko",
		moldova: "Moldawien",
		monaco: "Monaco",
		mongolia: "Mongolei",
		montenegro: "Republik Montenegro",
		morocco: "Marokko",
		myanmar: "Myanmar",
		netherlands: "Niederlande",
		new_Zealand: "Neuseeland",
		nepal: "Nepal",
		nigeria: "Nigeria",
		norway: "Norwegen",
		oman: "Oman",
		palestine: "Palästina",
		panama: "Panama",
		paraguay: "Paraguay",
		peru: "Peru",
		philippines: "die Philippinen",
		poland: "Polen",
		portugal: "Portugal",
		puerto_Rico: "Puerto Rico",
		qatar: "Katar",
		romania: "Rumänien",
		russia: "Russland",
		republic_of_Trinidad_and_Tobago: "Republik Trinidad und Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Saudi Arabien",
		serbia: "Serbien",
		singapore: "Singapur",
		slovakia: "Slowakei",
		slovenia: "Slowenien",
		south_Africa: "Südafrika",
		spain: "Spanien",
		sri_Lanka: "Sri Lanka",
		sweden: "Schweden",
		switzerland: "Schweiz",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tadschikistan",
		tanzania: "Tansania",
		thailand: "Thailand",
		turkey: "Türkiye",
		turkmenistan: "Turkmenistan",
		ukraine: "Ukraine",
		united_Arab_Emirates: "Vereinigte Arabische Emirate",
		united_Kingdom: "Großbritannien",
		united_States: "Vereinigte Staaten",
		uzbekistan: "Usbekistan",
		venezuela: "Venezuela",
		vietnam: "Vietnam",
		afghanistan: "Afghanistan",
		angola: "Angola",
		azerbaijan: "Aserbaidschan",
		bangladesh: "Bangladesch",
		belarus: "Belarus",
		belize: "BelizeCity in Germany",
		benin: "Benin",
		bhutan: "Bhutan",
		bolivia: "Bolivia",
		botswana: "Botswana",
		british_Virgin_Islands: "Britische Jungferninseln",
		burkina_Faso: "Burkina faso",
		burundi: "Burundi",
		cape_Verde: "Kap Verde",
		cayman_Islands: "Kaimaninseln",
		central_African_Republic: "Zentralafrikanische Republik",
		chad: "Tschad",
		comoros: "Komoren",
		the_Republic_of_the_Congo: "Kongo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Kongo (Kinshasa)",
		djibouti: "Dschibuti",
		ecuador: "Ecuador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Äquatorialguinea",
		eritrea: "Eritrea",
		fiji: "Fidschi",
		gabon: "Gabun",
		gambia: "Gambia",
		greenland: "Grönland",
		guatemala: "Guatemala",
		guinea: "Guinea",
		haiti: "Haiti",
		isle_of_Man: "Insel Man",
		cote_d_Ivoire: "Elfenbeinküste",
		jamaica: "Jamaika",
		jordan: "Jordanien",
		lebanon: "Libanon",
		lesotho: "Lesotho",
		liberia: "Liberia",
		libya: "Libyen",
		madagascar: "Madagaskar",
		malawi: "Malawi",
		maldives: "Malediven",
		mali: "Mali",
		mauritania: "Mauretanien",
		mauritius: "Mauritius",
		mozambique: "Mosambik",
		namibia: "Namibia",
		nicaragua: "Nicaragua",
		republic_of_Niger: "Niger",
		north_Korea: "Nordkorea",
		reunion: "Réunion",
		san_Marino: "San Marino",
		senegal: "Senegal",
		sierra_Leone: "sierra leone",
		somalia: "Somalia",
		sudan: "Sudan",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Syrien",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunesien",
		united_tates_Virgin_Islands: "Amerikanische Jungferninseln",
		uganda: "Uganda",
		uruguay: "Uruguay",
		vatican: "Vatikan",
		yemen: "Jemen",
		yugoslavia: "Jugoslawien",
		zambia: "Sambia",
		zimbabwe: "Zimbabwe",
		china: "China",
	}
};
