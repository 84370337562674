export default {
    foot: {
        nav1: "Home",
        nav2: "Market",
        nav3: "Trades",
        nav4: "Currency",
        nav5: "Account",
    },
    userLevel: {
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "Ordinary users",
        level1: "Silver users",
        level2: "Gold users",
        level3: "Platinum users",
        level4: "Diamond users",
        level5: "The King User",
        level6: "Ultimate user",
    },
    currenctType: {
        title: "Please select a currency",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'dollar',
        currenctType15: 'Hong Kong currency',
        currenctType16: 'Taiwan currency',
        currenctType17: 'ringgit',
        currenctType18: 'Singapore dollar',
        currenctType19: 'yen',
        currenctType20: 'euro',
        currenctType21: 'Dollar A',
        currenctType22: 'Rupiah',
        currenctType23: 'pound',
        currenctType24: 'Thai baht',
        currenctType25: 'Dollar',
        currenctType26: 'VND',
        currenctType27: 'Korean won',
        currenctType28: 'MOP',
    },
    account: {
        login: "Login",
        label_eMail: "Email",
        place_eMail: "Please enter your email address",
        tip_eMail: "Please enter your email address correctly",
        label_password: "Password",
        place_password: "Please enter your password",
        tip_password: "Please enter the correct password",
        no_account: "Don't have an account yet?" ,
        register: "Register",
        send: "Send verification code",
        label_code: "Email verification code",
        place_code: "Email verification code",
        tip_code: "Please enter email verification code",
    },
    assets: {
        title: "Assets",
        total_assets: "Total Assets",
        recharge: "Deposit",
        withdraw: "Withdraw",
        available: "Available",
        freeze: "Frozen",
        total: "Total",
        label_bank: "Currency",
        place_bank: "Please select a Currency",
        tip_bank: "Please select a Currency",
        label_wallet: "Wallet",
        place_wallet: "Please select a wallet",
        tip_wallet: "Please select a wallet",
        label_amount: "Amount",
        place_amount: "Please enter the amount",
        tip_amount: "Please enter the correct amount",
        label_password: "Password",
        place_password: "Please enter your password",
        tip_password: "Please enter the correct password",
        label_balance: "Available",
        all: "All",
        no_password: "Please  ",
        withdraw_psd: "set Withdrawal password",
    },
    currency: {
        position: "Position",
        drawer_title: "Options",
        now: "Price",
        open: "Open",
        volume: "Amount",
        height: "High",
        low: "Low",
        buy: "Buy/Up",
        sell: "Buy/Down",
        name: "Name",
        direction: "Direction",
        crm_order: "Confirm order",
        now_price: "Price",
        type_time: "Delivery time (left slide for higher returns)",
        second: "s",
        buy_amount: "Amount",
        available: "Available",
        service_fee: "Fees",
        min_number: "Minimunm buy ",
        errorTip1: "Incorrect amount input",
        errorTip2: "Insufficient available balance",
        auto_jump: "Automatically jump to order after",
        keep: "Continue placing orders",
        revenue: "Expected revenue",
    },
    home: {
        quick: "Quick coin flush",
        support: "Support",
        nav1: "Share",
        nav2: "Wallet",
        nav3: "News",
        nav4: "Tutorials",
        nav5: "Live chat",
        nav6: "Mining",
        nav7: "About Us",
        nav8: "New Coin",
        increase: "Top Gainers",
        volume: "Top Leaders",
        table_name: "Name",
        table_lastPrice: "Price",
        table_increase: "Change",
        table_volume: "24H VOL",
    },
    lockming: {
        profit: "Recent (daily earnings)",
        cycle: "financial cycle",
        limit: "Single limit",
        dividend_time: "Dividend payout time",
        every_day: "daily",
        funds: "Escrow funds",
        back: "Return on expiry",
        ransom: "Early redemption",
        label_amount: "Amount",
        place_amount: "Please enter,,,",
        available: "Available",
        expect_income: "Expected income",
        title: "Locked Mining",
        tip: "Mining for income",
        label_today_income: "Estimated earnings today",
        label_total_income: "Cumulative income",
        label_order: "Orders in escrow",
        label_number: "Single amount",
        daily_return: "Daily return",
        subscribe: "subscribe",
        position: "Position",
        label_state: "Status",
        subscribe_time: "subscription_time",
        expiration_time: "expiration time",
        income: "income",
        errorTip1: "Please enter the amount",
        tip_tit: "Are you sure you want to exit early",
    },
    market: {
        spots: "Spots",
        contract: "Futures",
        currency_trans: "Options",
        increase: "Top Gainers",
        volume: "Top Leaders",
        label_name: "Name",
        label_lastPrice: "Price",
        table_increase: "Change",
        table_volume: "24H VOL",
    },
    my: {
        details: "Details",
        help: "Help Center",
        information: "Information",
        language: "Language switch",
        password: {
            account_title: "Change password",
            withdraw_title: "Change password",
            label_oldPsd: "Old password",
            place_oldPsd: "Please enter your old password",
            label_newPsd: "New password",
            place_newPsd: "Please enter your new password",
            label_crmPsd: "Confirm password",
            place_crmPsd: "Please enter your password again",
            withdraw_tip: "If you have not set a withdrawal password, please leave it blank",
            password_tips: "The password must be a combination of 6-16 letters and numbers",
        },
        address: {
            title: "Wallet address",
            account_number: "Account number",
            add_btn: "Add address",
            label_type: "Select type",
            place_type: "Please select type",
            label_number: "Account",
            place_number: "Please enter the account number",
            wallet_address: 'Wallet address',
            bank_name: 'BANK Name',
            bank_address: "Bank address",
            name: "Name",
            payee_address: 'Beneficiary Address',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Branch Name",
            branch_code: "BRANCH CODE",
            bank_code: "Bank code",
            phone: "Cell-phone number",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Please enter content",
            bank_user: "Account Holder",
            bank_branch: "Branch",
            bank_isfc: "IFSC Code",
            bank_number: "Account Number",
            place_bank_name: "Please enter the bank name",
            place_bank_user: "Please enter the cardholder's name",
            place_bank_branch: "Please enter the branch name",
            place_bank_number: "Please enter your bank card number",
            place_bank_isfc: "Please enter ISFC",
        },
        history: {
            title: "History",
            recharge_record: "Recharge record",
            withdraw_record: "Withdrawal record",
            withdraw: "Withdrawals",
            number: "quantity",
            currency: "Amount",
            state: "Status",
            state0: "Pending",
            state1: "Completed",
            state2: "Rejected",
        },
        identity: {
            title: "Certification",
            label_country: "Nationality",
            place_country: "Please select a nationality",
            label_name: "Name",
            place_name: "Please enter your name",
            label_id: "ID number",
            place_id: "Please enter your ID/driver's license/passport number",
        },
        my: {
            list1: "Certification",
            list2: "Order History",
            list3: "Money Record",
            list4: "Wallet address",
            list5: "Switch language",
            list6: "Invite Friends",
            list7: "Customer Service",
            list8: "Tutorials",
            list9: "Change password",
            logout: "Sign out",
        },
        order: {
            title: "Order History",
            direction: "Direction",
            sell_price: "Sell price",
            number: "Quantity",
            type: "Delivery time",
            buy_price: "Buy price",
            profit: "P/L",
            sell_time: "Sell time",
        },
        share: {
            title: "Invite friends",
            save_ewm: "Save QR code",
            invite_link: "Invite link",
            copy_address: "Copy address",
            invite_code: "Registration code",
            copy_code: "Copy content",
            tip: "Register via the QR code or link above",
        },
    },
    transaction: {
        spots: "Spot",
        contract: "Futures",
        currency_trans: "Options",
        exchange: "Swap",
        sustainable: "Perpetual",
        warehouse: "Isolated",
        jump_lever: "Adjust Leverage",
        sell: "Sell",
        buy: "Buy",
        available: "Available",
        check_price: "Limit",
        market_price: "market",
        number: "Quantity",
        max: "Max",
        bail: "Cost",
        buy_long: "Buy/long",
        sell_short: "Sell/short ",
        last_price: "Price",
        now_entrust: "Open Orders",
        now_position: "Position",
        table_amount: "Amount",
        is_reduce: "Reduce Only",
        shi: "true",
        fou: "false",
        position_number: "Size",
        is_profit: "Unrealized PNL",
        wei: "not yet",
        open_price: "Entry price",
        mark_price: "Mark price",
        flat_price: "Liquidation",
        trim_lever: "Adjust leverage",
        flat_store: "Close position",
        trim_bail: "Adjust margin",
        reduce_bail: "reduce_margin",
        now_bail: "Current position margin",
        max_add: "Maximum increase",
        refer_flat: "Adjusted reference strong flat price",
        expect_profit: "Expected profit/loss"
    },
    common: {
        confirm: "Confirm",
        Cancel: "Cancle",
        all: "All",
        close: "Cancel",
        copy_success: "Copy successful!" ,
        copy_error: "Copy failed!" ,
        place_input: "Please enter content",
        more: "More",
        main: "Main",
        sub: "Subp",
        success: "Success!",
        cancel_tip: "Are you sure you want to cancel?" ,
        notice: "Notification",
        logout_tip: "Are you sure to exit?",
        tip: "Tip",
        day: "Day"
    },
    params: {
        transType0: "Spot",
        transType1: "Contract",
        directionType0: "Buy",
        directionType1: "Sell",
        lockStateType0: "Operation",
        lockStateType1: "Ended",
        lockStateType2: "Canceled",
    },
    city: {
        albania: "Albania",
        algeria: "Algeria",
        argentina: "Argentina",
        armenia: "Armenia",
        australia: "Australia",
        pakistan: "Pakistan",
        austria: "Austria",
        bahrain: "Bahrain",
        belgium: "Belgium",
        bosnia_and_Herzegovina: "Bosnia and Herzegovina",
        brazil: "Brazil",
        brunei: "Brunei",
        bulgaria: "Bulgaria",
        cambodia: "Cambodia",
        canada: "Canada",
        cameroon: "Cameroon",
        chile: "Chile",
        colombia: "Colombia",
        costa_Rica: "Costa Rica",
        croatia: "Croatia",
        cyprus: "Cyprus",
        czech_Republic: "Czech Republic",
        denmark: "Denmark",
        dominican_Republic: "Dominican Republic",
        egypt: "Egypt",
        estonia: "Estonia",
        ethiopia: "Ethiopia",
        finland: "Finland",
        france: "France",
        georgia: "Georgia",
        germany: "Germany",
        ghana: "Ghana",
        greece: "Greece",
        guyana: "Guyana",
        honduras: "Honduras",
        hong_Kong_China: "Hong Kong, China",
        hungary: "Hungary",
        iceland: "Iceland",
        ireland: "Ireland",
        italy: "Italy",
        india: "India",
        indonesia: "Indonesia",
        israel: "Israel",
        iran: "Iran",
        iraq: "Iraq",
        japan: "Japan",
        kazakstan: "Kazakhstan",
        kenya: "Kenya",
        korea: "Korea",
        kuwait: "Kuwait",
        kyrgyzstan: "Kyrgyzstan",
        laos: "Laos",
        latvia: "Latvia",
        lithuania: "Lithuania",
        luxembourg: "Luxembourg",
        macao_China: "Macao_China",
        macedonia: "Macedonia",
        malaysia: "Malaysia",
        malta: "Malta",
        mexico: "Mexico",
        moldova: "Moldova",
        monaco: "Monaco",
        mongolia: "Mongolia",
        montenegro: "Montenegro",
        morocco: "Morocco",
        myanmar: "Myanmar",
        netherlands: "Netherlands",
        new_Zealand: " New_Zealand",
        nepal: "Nepal",
        nigeria: "Nigeria",
        norway: "Norse",
        oman: "Oman",
        palestine: "Palestine",
        panama: "Panama",
        paraguay: "Paraguay",
        peru: "Peru",
        philippines: "Philippines",
        poland: "Poland",
        portugal: "Portugal",
        puerto_Rico: "Puerto Rico",
        qatar: "Qatar",
        romania: "Romania",
        russia: "Russia",
        republic_of_Trinidad_and_Tobago: "Republic of Trinidad and Tobago",
        rwanda: "Rwanda",
        saudi_Arabia: "Saudi Arabia",
        serbia: "Serbia",
        singapore: "Singapore",
        slovakia: "Slovakia",
        slovenia: "Slovenia",
        south_Africa: "South Africa",
        spain: "Spain",
        sri_Lanka: "Sri Lanka",
        sweden: "Sweden",
        switzerland: "Switzerland",
        taiwan_China: "Taiwan_China",
        tajikistan: "Tajikistan",
        tanzania: "Tanzania",
        thailand: "Thailand",
        turkey: "Turkey",
        turkmenistan: "Turkmenistan",
        ukraine: "Ukraine",
        united_Arab_Emirates: "United Arab Emirates",
        united_Kingdom: "United Kingdom",
        united_States: " United_States",
        uzbekistan: "Uzbekistan",
        venezuela: "Venezuela",
        vietnam: "Vietnam",
        afghanistan: "Afghanistan",
        angola: "Angola",
        azerbaijan: "Azerbaijan",
        bangladesh: "Bangladesh",
        belarus: "Belarus",
        belize: "Belize",
        benin: "Benin",
        bhutan: "Bhutan",
        bolivia: "Bolivia",
        botswana: "Botswana",
        british_Virgin_Islands: "British Virgin Islands",
        burkina_Faso: "Burkina Faso",
        burundi: "Burundi",
        cape_Verde: "Cape Verde",
        cayman_Islands: "Cayman Islands",
        central_African_Republic: "Central African Republic",
        chad: "Chad",
        comoros: "Comoros",
        the_Republic_of_the_Congo: " The_Republic_of_the_Congo",
        democratic_Republic_of_the_Congo: "Democratic Republic of the Congo",
        djibouti: "Djibouti",
        ecuador: "Ecuador",
        el_Salvador: "El Salvador",
        equatorial_Guinea: "Equatorial Guinea",
        eritrea: "Eritrea",
        fiji: "Fiji",
        gabon: "Gabon",
        gambia: "Gambia",
        greenland: "Greenland",
        guatemala: "Guatemala",
        guinea: "Guinea",
        haiti: "Haiti",
        isle_of_Man: " Isle_of_Man",
        cote_d_Ivoire: "Cote_d_Ivoir",
        jamaica: "Jamaica",
        jordan: "Jordan",
        lebanon: "Lebanon",
        lesotho: "Lesotho",
        liberia: "Liberia",
        libya: "Libya",
        madagascar: "Madagascar",
        malawi: "Malawi",
        maldives: "Maldives",
        mali: "Mali",
        mauritania: "Mauritania",
        mauritius: "Mauritius",
        mozambique: "Mozambique",
        namibia: "Namibia",
        nicaragua: "Nicaragua",
        republic_of_Niger: "Republic_of_Niger",
        north_Korea: " North_Korea",
        reunion: "Reunion",
        san_Marino: "SAN Marino",
        senegal: "Senegal",
        sierra_Leone: "Sierra Leone",
        somalia: "Somalia",
        sudan: "Sudan",
        suriname: "Suriname",
        eswatini: "Eswatini",
        syria: "Syria",
        togo: "Togo",
        tonga: "Tonga",
        tunisia: "Tunisia",
        united_States_Virgin_Islands: "United States Virgin Islands",
        uganda: "Uganda",
        uruguay: "Uruguay",
        vatican: "Vatican",
        yemen: "Yemen",
        yugoslavia: "Yugoslavia",
        zambia: "Zambia",
        zimbabwe: "Zimbabwe",
        china: "China",
     }
};
