export default {
    foot: {
        nav1: "Página inicial",
        nav2: "A situação atual do mercado",
        nav3: "Negociações",
        nav4: "Moeda",
        nav5: "Responsabilidades",
    },
    userLevel: {
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "Utilizadores comuns",
        level1: "Utilizadores prateados",
        level2: "Utilizadores de ouro",
        level3: "Utilizadores Platinum",
        level4: "Utilizadores de diamantes",
        level5: "Utilizadores O Rei",
        level6: "Utilizador final",
    },
    currenctType: {
        title: "Selecione a moeda",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'Dólar americano',
        currenctType15: 'Dólar de Hong Kong',
        currenctType16: 'Novo dólar taiwanês',
        currenctType17: 'Dólar malaio',
        currenctType18: 'Nova moeda',
        currenctType19: 'Iene japonês (unidade monetária)',
        currenctType20: 'Euro (moeda)',
        currenctType21: 'Dólar australiano',
        currenctType22: 'Rupia indonésia',
        currenctType23: 'Libra esterlina',
        currenctType24: 'Baht tailandês',
        currenctType25: 'Dólar canadense',
        currenctType26: 'Rupia vietnamita',
        currenctType27: 'Won sul-coreano (unidade monetária)',
        currenctType28: 'Dólar de Macau',
    },
    account: {
        login: "Entrar (dados)",
        label_eMail: "Caixa de entrada",
        place_eMail: "Digite seu endereço de e-mail",
        tip_eMail: "Digite seu endereço de e-mail corretamente",
        label_password: "Criptográfico",
        place_password: "Digite sua senha",
        tip_password: "Digite a senha correta",
        no_account: "Ainda não tenho uma conta.？",
        register: "Registro",
        send: "CAPTCHA",
        label_code: "CAPTCHA",
        place_code: "CAPTCHA",
        tip_code: "Digite seu código de verificação de e-mail",
    },
    assets: {
        title: "Responsabilidades",
        total_assets: "Total de ativos",
        recharge: "Recarga (dinheiro em um cartão)",
        withdraw: "Retirar fundos",
        available: "Usabilidade",
        freeze: "Congelamento (empréstimo, salário, preço etc.)",
        total: "Total (geral)",
        label_bank: "Tipo de moeda",
        place_bank: "Selecione a moeda",
        tip_bank: "Selecione a moeda",
        label_amount: "Soma de dinheiro",
        place_amount: "Digite o valor",
        tip_amount: "Digite o valor correto",
        label_password: "Criptográfico",
        place_password: "Digite sua senha",
        tip_password: "Digite a senha correta",
        label_balance: "USDT disponível",
        all: "Retirada de todos os fundos",
        no_password: "Para sua segurança financeira, defina",
        withdraw_psd: "Senha de retirada",
        label_wallet: "Bolsas",
        place_wallet: "Selecione a carteira",
        tip_wallet: "Selecione a carteira",
    },
    currency: {
        position: "Participações",
        drawer_title: "Negociação de moedas",
        now: "Estar de frente para (nós)",
        open: "Preço inicial da ação",
        volume: "Faturamento",
        height: "Supremo",
        low: "Mais baixo",
        buy: "Suborno",
        sell: "Vender a descoberto",
        name: "Nome (de uma pessoa ou coisa)",
        direction: "Orientações",
        crm_order: "Confirmar pedido",
        now_price: "Valor presente",
        type_time: "Tempo de entrega (deslize para a esquerda para retornos mais altos)",
        second: "Minuto e segundo",
        buy_amount: "Valor do buy-in",
        available: "Usabilidade",
        service_fee: "Taxa de manuseio",
        min_number: "Menor",
        errorTip1: "Valor inserido incorretamente",
        errorTip2: "Saldo disponível insuficiente",
        auto_jump: "Saltar automaticamente a ordem após",
        keep: "Continue fazendo o pedido.",
        revenue: "Retorno esperado",
    },
    home: {
        quick: "Fast Coin Rush",
        support: "Adjuvante",
        nav1: "Compartilhar",
        nav2: "Bolsas",
        nav3: "Informações",
        nav4: "Tutoriais",
        nav5: "Primeiros socorros",
        nav6: "Escavação",
        nav7: "Sobre nós",
        nav8: "Assinatura de novas moedas",
        increase: "Aumento e queda de ações em um dia de negociação",
        volume: "Saturamento",
        table_name: "Nome (de uma coisa)",
        table_lastPrice: "Preço mais recente",
        table_increase: "Aumento ou queda no preço",
        table_volume: "Faturamento",
    },
    lockming: {
        profit: "Recentemente (ganhos diários)",
        cycle: "Ciclo financeiro",
        limit: "Limite de curso único",
        dividend_time: "Momento do pagamento de dividendos",
        every_day: "(sopa etc.) do dia",
        funds: "Fundos mantidos em fideicomisso",
        back: "Retorno no vencimento",
        ransom: "Resgate antecipado",
        label_amount: "Valor penhorado",
        place_amount: "Favor entrar...",
        available: "Usabilidade",
        expect_income: "Receita estimada",
        title: "Bloqueio de mineração",
        tip: "Mineração",
        label_today_income: "Retorno esperado hoje",
        label_total_income: "Ganho acumulado",
        label_order: "Pedidos hospedados",
        label_number: "Valor único",
        daily_return: "Taxa de retorno diária",
        subscribe: "Pedir para comprar",
        position: "Participações",
        label_state: "Estado das coisas",
        subscribe_time: "Inscrever-se em algo",
        expiration_time: "Tempo devido",
        income: "Prosseguir",
        errorTip1: "Digite o valor",
        tip_tit: "Tem certeza de que deseja sair mais cedo?",
    },
    market: {
        spots: "Mercadorias em estoque",
        contract: "Contratual",
        currency_trans: "Negociação de moedas",
        increase: "Aumento e queda de ações em um dia de negociação",
        volume: "Faturamento",
        label_name: "Nome (de uma coisa)",
        label_lastPrice: "Preço mais recente",
        table_increase: "Aumento ou queda no preço",
        table_volume: "Faturamento",
    },
    my: {
        details: "Detalhes",
        help: "Central de Ajuda",
        information: "Informações",
        language: "Mudança de idioma",
        password: {
            account_title: "Alterar sua senha",
            withdraw_title: "Alterar sua senha",
            label_oldPsd: "Senha antiga",
            place_oldPsd: "Digite a senha antiga",
            label_newPsd: "Nova senha",
            place_newPsd: "Digite uma nova senha",
            label_crmPsd: "Confirmar senha",
            place_crmPsd: "Digite a nova senha novamente",
            withdraw_tip: "Se você não tiver definido uma senha para sacar dinheiro, digite sua senha de login",
            password_tips: "A senha deve ser uma combinação de letras + números de 6 a 16 dígitos",
        },
        address: {
            title: "Endereço de retirada",
            account_number: "Número da conta",
            add_btn: "Adicionar endereço",
            label_type: "Selecione o tipo",
            place_type: "Selecione o tipo",
            label_number: "Número da conta",
            place_number: "Digite o número de sua conta",
            wallet_address: 'Endereço da carteira',
            bank_name: 'Nome do banco',
            bank_address: "Endereço do banco",
            name: "Nome e sobrenome",
            payee_address: 'Endereço do beneficiário',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "Nome da filial",
            branch_code: "Código de linha",
            bank_code: "Código do banco",
            phone: "Número de telefone celular",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "Digite o conteúdo",
            bank_user: "Porta-cartões",
            bank_branch: "Ramo",
            bank_isfc: "ISFC",
            bank_number: "Número do cartão bancário",
            place_bank_name: "Indique por favor o nome do banco",
            place_bank_user: "Indique o nome do titular do cartão",
            place_bank_branch: "Indique por favor o nome da filial",
            place_bank_number: "Introduza o número do seu cartão bancário",
            place_bank_isfc: "Indique ISFC",
        },
        history: {
            title: "Registro histórico",
            recharge_record: "Registro de recarga",
            withdraw_record: "Registros de retirada",
            recharge: "Recarga (dinheiro em um cartão)",
            withdraw: "Retirar fundos",
            number: "Quantidades",
            currency: "Moeda",
            state: "Estado das coisas",
            state0: "Aguardando aprovação",
            state1: "Feito",
            state2: "Rejeitado",
        },
        identity: {
            title: "Credenciamento",
            label_country: "Selecione a nacionalidade",
            place_country: "Selecione a nacionalidade",
            label_name: "Nome e sobrenome",
            place_name: "Digite seu nome",
            label_id: "Número do certificado",
            place_id: "Digite o número do seu documento de identidade/carteira de motorista/passaporte",
        },
        my: {
            list1: "Credenciamento",
            list2: "Gerenciamento de pedidos",
            list3: "Registros de financiamento",
            list4: "Endereço de retirada",
            list5: "Trocar de idioma",
            list6: "Convidar amigos",
            list7: "Primeiros socorros",
            list8: "Tutoriais",
            list9: "Alterar sua senha",
            logout: "Fazer logout",
        },
        order: {
            title: "Participações",
            direction: "Orientações",
            sell_price: "Taxa de venda",
            number: "Quantidades",
            type: "Tempo de entrega",
            buy_price: "Preço da oferta",
            profit: "Crescente e decrescente",
            sell_time: "Tempo de esgotamento",
        },
        share: {
            title: "Convidar amigos",
            save_ewm: "Salvar código QR",
            invite_link: "Link do convite",
            copy_address: "Endereço para cópia",
            invite_code: "Código de registro",
            copy_code: "Copiar conteúdo",
            tip: "Registre-se por meio do código QR ou do link acima",
        },
    },
    transaction: {
        spots: "Mercadorias em estoque",
        contract: "Contratual",
        currency_trans: "Negociação de moedas",
        exchange: "Dinheiro",
        sustainable: "Sustentabilidade",
        warehouse: "Barnstorming",
        jump_lever: "Alavancagem (ou seja, compra de ações em uma ponte)",
        sell: "Alcance (um preço em um leilão)",
        buy: "Adesão",
        available: "Usabilidade",
        check_price: "Limite de preço",
        market_price: "Valor de mercado",
        number: "Quantidades",
        max: "Maior",
        bail: "Margem (em negociações de derivativos)",
        buy_long: "Suborno",
        sell_short: "Vender a descoberto",
        last_price: "Preço mais recente",
        now_entrust: "Posições históricas",
        now_position: "Posição atual",
        table_amount: "Saque em dinheiro",
        is_reduce: "Vender para baixo (ou seja, reduzir a posição)",
        shi: "Ser",
        fou: "Entupido",
        position_number: "Número de posições",
        is_profit: "Obter lucro",
        wei: "Não",
        open_price: "Preço de abertura",
        mark_price: "Preço marcado",
        flat_price: "Preço de Qiangping",
        trim_lever: "Ajustar a alavanca",
        flat_store: "Fechar uma posição",
        trim_bail: "Margem de ajuste",
        reduce_bail: "Redução da margem",
        now_bail: "Margem da posição atual",
        max_add: "Aumento máximo",
        refer_flat: "Paridade forte de referência ajustada",
        expect_profit: "Lucros e perdas projetados",
    },
    common: {
        confirm: "Reconhecer",
        Cancel: "Cancelamentos",
        all: "Completo",
        close: "Cancelamentos",
        copy_success: "Sucesso na cópia！",
        copy_error: "Falha na reprodução！",
        place_input: "Digite o conteúdo",
        more: "Mais",
        main: "Imagem principal",
        sub: "Subtítulo",
        success: "Sucessos！",
        cancel_tip: "Tem certeza de que deseja cancelar??",
        notice: "Notificações",
        logout_tip: "Confirmação de saída？",
        tip: "Chamar a atenção para algo",
        day: "Dia"
    },
    params: {
        transType0: "Mercadorias em estoque",
        transType1: "Contratual",
        directionType0: "Adesão",
        directionType1: "Alcance (um preço em um leilão)",
        lockStateType0: "Em execução",
        lockStateType1: "Fechado",
        lockStateType2: "Cancelada",
    },
    city: {
		albania: "Albânia",
		algeria: "Argélia",
		argentina: "Argentina",
		armenia: "Arménia",
		australia: "Continente",
		pakistan: "Paquistão",
		austria: "Continente",
		bahrain: "Bahrein",
		belgium: "Bélgica",
		bosnia_and_Herzegovina: "Bósnia e Herzegovina",
		brazil: "Brasil",
		brunei: "Brunei",
		bulgaria: "Bulgária",
		cambodia: "Camboja",
		canada: "Canadá",
		cameroon: "Camarões",
		chile: "Chile",
		colombia: "Colômbia",
		costa_Rica: "Costa Rica",
		croatia: "Croácia",
		cyprus: "Chipre",
		czech_Republic: "República Checa",
		denmark: "Dinamarca",
		dominican_Republic: "República Dominicana",
		egypt: "Egipto",
		estonia: "Estónia",
		ethiopia: "Etiópia",
		finland: "Finlândia",
		france: "França",
		georgia: "Geórgia",
		germany: "Alemanha",
		ghana: "Gana",
		greece: "Grécia",
		guyana: "Guiana",
		honduras: "Honduras",
		hong_Kong_China: "Hong Kong, China",
		hungary: "Hungria",
		iceland: "Islândia",
		ireland: "Irlanda",
		italy: "Itália",
		india: "Índia",
		indonesia: "Indonésia",
		israel: "Israel",
		iran: "Irão",
		iraq: "Iraque",
		japan: "Japão",
		kazakstan: "Cazaquistão",
		kenya: "Quénia",
		korea: "Coreia",
		kuwait: "Kuwait",
		kyrgyzstan: "Quirguizistão",
		laos: "Laos",
		latvia: "Letónia",
		lithuania: "Lituânia",
		luxembourg: "Luxemburgo",
		macao_China: "Macau, China",
		macedonia: "Macedónia",
		malaysia: "Malásia",
		malta: "Malta",
		mexico: "México",
		moldova: "Moldávia",
		monaco: "Mônaco",
		mongolia: "Mongólia",
		montenegro: "Montenegro",
		morocco: "Marrocos",
		myanmar: "MianmarName",
		netherlands: "Países Baixos",
		new_Zealand: "Nova Zelândia",
		nepal: "Nepal",
		nigeria: "Nigéria",
		norway: "Noruega",
		oman: "Omã",
		palestine: "Palestina",
		panama: "Panamá",
		paraguay: "Paraguai",
		peru: "Peru",
		philippines: "Filipinas",
		poland: "poland",
		portugal: "Portugal",
		puerto_Rico: "Porto Rico",
		qatar: "Qatar",
		romania: "Roménia",
		russia: "Rússia",
		republic_of_Trinidad_and_Tobago: "Trinidad e Tobago",
		rwanda: "Ruanda",
		saudi_Arabia: "Arábia Saudita",
		serbia: "Sérvia",
		singapore: "Singapura",
		slovakia: "Eslováquia",
		slovenia: "Eslovénia",
		south_Africa: "África do Sul",
		spain: "Espanha",
		sri_Lanka: "Sri Lanka",
		sweden: "Suécia",
		switzerland: "Suíça",
		taiwan_China: "Taiwan, China",
		tajikistan: "Tajiquistão",
		tanzania: "Tanzânia",
		thailand: "Tailândia",
		turkey: "Türkiye",
		turkmenistan: "Turquemenistão",
		ukraine: "Ucrânia",
		united_Arab_Emirates: "EAU",
		united_Kingdom: "Grã-Bretanha",
		united_States: "Estados Unidos",
		uzbekistan: "Usbequistão",
		venezuela: "Venezuela",
		vietnam: "Vietname",
		afghanistan: "Afeganistão",
		angola: "Angola",
		azerbaijan: "Azerbaijão",
		bangladesh: "Bangladesh",
		belarus: "Bielorrússia",
		belize: "Belizefrance. kgm",
		benin: "Benin",
		bhutan: "Butão",
		bolivia: "bolívia",
		botswana: "Botsuana",
		british_Virgin_Islands: "Ilhas Virgens Britânicas",
		burkina_Faso: "burkina faso",
		burundi: "Burundi",
		cape_Verde: "Cabo Verde",
		cayman_Islands: "Ilhas Caimão",
		central_African_Republic: "República Centro-Africana",
		chad: "ChadeName",
		comoros: "Comores",
		the_Republic_of_the_Congo: "Congo (Brazzaville)",
		democratic_Republic_of_the_Congo: "Congo (Kinshasa)",
		djibouti: "JibutiName",
		ecuador: "Equador",
		el_Salvador: "El Salvador",
		equatorial_Guinea: "Guiné Equatorial",
		eritrea: "Eritreia",
		fiji: "Fiji",
		gabon: "Gabão",
		gambia: "Gâmbia",
		greenland: "Gronelândia",
		guatemala: "Guatemala",
		guinea: "Guiné",
		haiti: "Haiti",
		isle_of_Man: "Ilha de Man",
		cote_d_Ivoire: "Costa do Marfim",
		jamaica: "Jamaica",
		jordan: "Jordânia",
		lebanon: "Líbano",
		lesotho: "Lesoto",
		liberia: "Libéria",
		libya: "Líbia",
		madagascar: "Madagascar",
		malawi: "Malawi",
		maldives: "Maldivas",
		mali: "Mali",
		mauritania: "Mauritânia",
		mauritius: "Maurícia",
		mozambique: "Moçambique",
		namibia: "Namíbia",
		nicaragua: "Nicarágua",
		republic_of_Niger: "Níger",
		north_Korea: "Coreia do Norte",
		reunion: "Reunião",
		san_Marino: "San Marino",
		senegal: "SenegalName",
		sierra_Leone: "sierra leone",
		somalia: "Somália",
		sudan: "Sudão",
		suriname: "Suriname",
		eswatini: "Eswatini",
		syria: "Síria",
		togo: "Togo",
		tonga: "Tonga",
		tunisia: "Tunísia",
		united_States_Virgin_Islands: "Ilhas Virgens, Estados Unidos",
		uganda: "Uganda",
		uruguay: "Uruguai",
		vatican: "Vaticano",
		yemen: "Iémen",
		yugoslavia: "Jugoslávia",
		zambia: "Zâmbia",
		zimbabwe: "zimbabwe",
		china: "China",
	}
};
