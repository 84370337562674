export default {
    foot: {
        nav1: "홈 페이지",
        nav2: "시세",
        nav3: "거래",
        nav4: "화폐",
        nav5: "자산",
    },
    userLevel: {
        score: "Credit Score",
        up: "Up",
        down: "Down",
        inviteId: "Inviter",
        inviteIdMessage: "Please enter the inviter ID",
        level0: "일반 사용자",
        level1: "실버 사용자",
        level2: "골드 사용자",
        level3: "플래티넘 사용자",
        level4: "다이아몬드 사용자",
        level5: "왕자 사용자",
        level6: "지존 사용자",
    },
    currenctType: {
        title: "화폐를 선택하세요",
        currenctType6: 'USDT-ERC20',
        currenctType7: 'USDT-TRC20',
        currenctType8: 'BTC',
        currenctType13: 'ETH',
        currenctType14: 'ドル',
        currenctType15: '香港ドル',
        currenctType16: '台湾ドル',
        currenctType17: 'コイン枚',
        currenctType18: '新貨',
        currenctType19: '円',
        currenctType20: 'ユーロ',
        currenctType21: '豪ドル',
        currenctType22: 'インドネシア盾',
        currenctType23: 'ポンド',
        currenctType24: 'バーツ',
        currenctType25: 'かさん',
        currenctType26: 'ベトナムドン',
        currenctType27: '韓国ドル',
        currenctType28: 'マカオドル',
    },
    account: {
        login: "로그인",
        label_eMail: "메일함",
        place_eMail: "이메일 주소를 입력하세요",
        tip_eMail: "이메일 주소를 정확하게 입력하세요",
        label_password: "비밀번호",
        place_password: "비밀번호를 입력하세요",
        tip_password: "올바른 비밀번호를 입력하세요",
        no_account: "아직 계정이 없으신가요?" ,
        register: "등록하세요",
        send: "인증 코드 보내기",
        label_code: "이메일 인증 코드",
        place_code: "이메일 인증 코드",
        tip_code: "이메일 인증 코드를 입력하세요",
    },
    assets: {
        title: "자산",
        total_assets: "총 자산",
        recharge: "재충전",
        withdraw: "인출",
        available: "사용 가능",
        freeze: "동결",
        total: "총액",
        label_bank: "화폐 종류",
        place_bank: "코인을 선택하세요",
        tip_bank: "코인을 선택하세요",
        label_amount: "금액",
        place_amount: "금액을 입력하세요",
        tip_amount: "정확한 금액을 입력하세요",
        label_password: "비밀번호",
        place_password: "비밀번호를 입력하세요",
        tip_password: "올바른 비밀번호를 입력하세요",
        label_balance: "사용 가능한 USDT",
        all: "모든 출금",
        no_password: "자금의 안전을 위해 설정해 주세요",
        withdraw_psd: "출금 비밀번호",
        label_wallet: "지갑",
        place_wallet: "지갑을 선택하세요",
        tip_wallet: "지갑을 선택하세요",
    },
    currency: {
        position: "위치",
        drawer_title: "currency_trade",
        now: "현재",
        open: "열기",
        volume: "볼륨",
        height: "최고",
        low: "최저",
        buy: "매수 매수",
        sell: "공매도",
        name: "이름",
        direction: "방향",
        crm_order: "주문 확인",
        now_price: "현재 가격",
        type_time: "배송 시간(더 높은 수익을 위해 왼쪽 슬라이드)",
        second: "초",
        buy_amount: "구매 금액",
        available: "사용 가능",
        service_fee: "수수료",
        min_number: "최소",
        errorTip1: "금액 입력 오류",
        errorTip2: "사용 가능한 잔액 부족",
        auto_jump: "後自動受注ジャンプ",
        keep: "주문 계속",
        revenue: "예상 이익",
    },
    home: {
        quick: "빠른 동전 세척",
        support: "지원",
        nav1: "공유",
        nav2: "지갑",
        nav3: "정보",
        nav4: "튜토리얼",
        nav5: "고객 서비스",
        nav6: "채굴",
        nav7: "회사 소개",
        nav8: "새로운 코인 구독",
        increase : "업 목록",
        volume: "볼륨",
        table_name: "이름",
        table_lastPrice: "최근 가격",
        table_increase: "상승/하락",
        table_volume: "거래량",
    },
    lockming: {
        profit: "최근(일일 수익)",
        cycle: "재무 주기",
        limit: "단일 한도",
        dividend_time: "배당금 지급 시간",
        every_day: "매일",
        funds: "에스크로 자금",
        back: "만기 시 수익률",
        ransom: "조기 상환",
        label_amount: "서약 금액",
        place_amount: "입력하세요,,,",
        available: "사용 가능",
        expect_income: "예상 수입",
        title: "잠긴 채굴",
        tip: "수입을 위한 채굴",
        label_today_income: "오늘 예상 수입",
        label_total_income: "누적 수입",
        label_order: "에스크로에 있는 주문",
        label_number: "단일 금액",
        daily_return: "일일 수익",
        subscribe: "구독",
        position: "포지션",
        label_state: "상태",
        subscribe_time: "구독 시간",
        expiration_time: "만료 시간",
        income: "수입",
        errorTip1: "금액을 입력하세요",
        tip_tit: "조기 탈퇴하시겠습니까?",
    },
    market: {
        spots: "현물",
        contract: "계약",
        currency_trans: "화폐 거래",
        increase: "상승폭 순위",
        volume: "거래량",
        label_name: "이름",
        label_lastPrice: "최신 가격",
        table_increase: "등락폭",
        table_volume: "거래액",
    },
    my: {
        details: "세부 정보",
        help: "도움말 센터",
        information: "정보",
        language: "언어 전환",
        password: {
            account_title: "비밀번호 변경",
            withdraw_title: "비밀번호 변경",
            label_oldPsd: "이전 비밀번호",
            place_oldPsd: "이전 비밀번호를 입력하세요",
            label_newPsd: "새 비밀번호",
            place_newPsd: "새 비밀번호를 입력하세요",
            label_crmPsd: "비밀번호 확인",
            place_crmPsd: "새 비밀번호를 다시 입력하세요",
            withdraw_tip: "출금 비밀번호를 설정하지 않은 경우 비워두세요",
            password_tips: "암호는 6 ~ 16자리 + 숫자의 조합이어야 합니다.",
        },
        address: {
            title: "지갑 주소",
            account_number: "계좌 번호",
            add_btn: "주소 추가",
            label_type: "유형 선택",
            place_type: "유형을 선택하세요",
            label_number: "계좌번호",
            place_number: "계좌번호를 입력하세요",
            wallet_address: '지갑 주소',
            bank_name: '은행 이름',
            bank_address: "은행 주소",
            name: "이름",
            payee_address: '수취인 주소',
            swift_code: "Swift/Swift Code/BIC",
            aba: "ABA",
            branch_number: "지점 이름",
            branch_code: "지점 코드",
            bank_code: "은행 코드",
            phone: "핸드폰 번호",
            iban: "IBAN",
            sort_code: "Sort Code",
            place_inner: "내용을 입력하십시오.",
            bank_user: "카드 소지자",
            bank_branch: "지점",
            bank_isfc: "ISFC",
            bank_number: "은행카드 번호",
            place_bank_name: "은행 이름을 입력하십시오.",
            place_bank_user: "카드 소지자 이름을 입력하십시오.",
            place_bank_branch: "지점 이름을 입력하십시오.",
            place_bank_number: "은행카드 번호를 입력하십시오.",
            place_bank_isfc: "ISFC 를 입력하십시오.",
        },
        history: {
            title: "내역",
            recharge_record: "충전 기록",
            withdraw_record: "출금 기록",
            withdraw: "출금",
            number: "숫자",
            currency: "통화",
            state: "상태",
            state0: "보류 중",
            state1: "완료됨",
            state2: "거부됨",
        },
        identity: {
            title: "인증",
            label_country: "국적 선택",
            place_country: "국적을 선택하세요",
            label_name: "이름",
            place_name: "이름을 입력하세요",
            label_id: "ID 번호",
            place_id: "신분증/운전면허증/여권 번호를 입력하세요",
        },
        my: {
            list1: "인증",
            list2: "주문 관리",
            list3: "자금 기록",
            list4: "지갑 주소",
            list5: "언어 전환",
            list6: "친구 초대",
            list7: "고객 서비스",
            list8: "튜토리얼",
            list9: "비밀번호 변경",
            logout: "로그아웃",
        },
        order: {
            title: "위치",
            direction: "방향",
            sell_price: "판매율",
            number: "수량",
            type: "배달 시간",
            buy_price: "입찰 가격",
            profit: "이익/손실",
            sell_time: "판매 시간",
        },
        share: {
            title: "친구 초대",
            save_ewm: "QR 코드 저장",
            invite_link: "링크 초대",
            copy_address: "주소 복사",
            invite_code: "등록 코드",
            copy_code: "콘텐츠 복사",
            tip: "QR코드 또는 위의 링크를 통해 등록하세요",
        },
    },
    transaction: {
        spots: "스팟",
        contract: "계약",
        currency_trans: "통화_거래",
        exchange: "플래시",
        sustainable: "영구",
        warehouse: "포지션별",
        jump_lever: "점프 레버리지",
        sell: "판매",
        buy: "구매",
        available: "사용 가능",
        check_price: "제한가격",
        market_price: "시장_가격",
        number: "수량",
        max: "최대",
        bail: "마진",
        buy_long: "매수 매수",
        sell_short: "공매도",
        last_price: "최근 가격",
        NOW_ENTRUST: "현재_위탁",
        now_position: "현재 포지션",
        table_amount: "출금 금액",
        is_reduce: "포지션만 감소",
        shi: "예",
        fou: "아니오",
        position_number: "보유 포지션 수",
        is_profit: "실현된 이익",
        wei: "아직",
        open_price: "시가",
        mark_price: "표시 가격",
        flat_price: "강한_수준_가격",
        trim_lever: "레버리지 조정",
        flat_store: "포지션 닫기",
        trim_bail: "마진 조정",
        reduce_bail: "마진 줄이기",
        NOW_BAIL: "현재 포지션 마진",
        max_add: "최대 증가",
        refer_flat: "조정된 참조 강한 플랫 가격",
        expect_profit: "예상 이익/손실"
    },
    common: {
        confirm: "확인",
        all: "모두",
        close: "취소",
        copy_success: "복사 성공!" ,
        copy_error: "복사 실패!" ,
        place_input: "내용을 입력하세요",
        more: "추가",
        main: "기본",
        sub: "부도",
        success: "성공!",
        cancel_tip: "정말 취소하시겠습니까?" ,
        notice: "알림",
        logout_tip: "종료하시겠습니까?",
        tip: "힌트",
        day: "일"
    },
    params: {
        transType0: "현물",
        transType1: "계약",
        directionType0: "매입",
        directionType1: "매도",
        lockStateType0: "실행 중",
        lockStateType1: "종료",
        lockStateType2: "취소됨",
    },
    city: {
        albania: "알바니아",
        algeria: "알제리",
        argentina: "아르헨티나",
        armenia: "아르메니아",
        australia: "호주",
        pakistan: "파키스탄",
        austria: "오스트리아",
        bahrain: "바레인",
        belgium: "벨기에",
        bosnia_and_Herzegovina: "보스니아 헤르체고비나",
        brazil: "브라질",
        brunei: "브루나이",
        bulgaria: "불가리아",
        cambodia: "캄보디아",
        canada: "캐나다",
        cameroon: "카메룬",
        chile: "칠레",
        colombia: "콜롬비아",
        costa_Rica: "코스타리카",
        croatia: "크로아티아",
        cyprus: "키프로스",
        czech_Republic: "체코",
        denmark: "덴마크",
        dominican_Republic: "도미니카",
        egypt: "이집트",
        estonia: "에스토니아",
        ethiopia: "에티오피아",
        finland: "핀란드",
        france: "프랑스",
        georgia: "그루지야",
        germany: "독일",
        ghana: "가나",
        greece: "그리스",
        guyana: "가이아나",
        honduras: "온두라스",
        hong_Kong_China: "중국 홍콩",
        hungary: "헝가리",
        iceland: "아이슬란드",
        ireland: "아일랜드",
        italy: "이탈리아",
        india: "인도",
        indonesia: "인도네시아",
        israel: "이스라엘",
        iran: "이란",
        iraq: "이라크",
        japan: "일본",
        kazakstan: "카자흐스탄",
        kenya: "케냐",
        korea: "한국",
        kuwait: "쿠웨이트",
        kyrgyzstan: "키르기스스탄",
        laos: "라오스",
        Latvia: "라트비아",
        lithuania: "리투아니아",
        luxembourg: "룩셈부르크",
        macao_China: "중국 마카오",
        macedonia: "마케도니아",
        malaysia: "말레이시아",
        malta: "몰타",
        mexico: "멕시코",
        moldova: "몰도바",
        monaco: "모나코",
        mongolia: "몽골",
        montenegro: "몬테네그로",
        morocco: "모로코",
        myanmar: "미얀마",
        netherlands: "네덜란드",
        new_Zealand: "뉴질랜드",
        nepal: "네팔",
        nigeria: "나이지리아",
        norway: "노르웨이",
        oman: "오만",
        palestine: "팔레스타인",
        panama: "파나마",
        paraguay: "파라과이",
        peru: "페루",
        philippines: "필리핀",
        poland: "폴란드",
        portugal: "포르투갈",
        puerto_Rico: "푸에르토리코",
        qatar: "카타르",
        romania: "루마니아",
        russia: "러시아",
        republic_of_Trinidad_and_Tobago: "트리니다드 토바고",
        rwanda: "르완다",
        saudi_Arabia: "사우디아라비아",
        serbia: "세르비아",
        singapore: "싱가포르",
        slovakia: "슬로바키아",
        slovenia: "슬로베니아",
        south_Africa: "남아프리카",
        spain: "스페인",
        sri_Lanka: "스리랑카",
        sweden: "스웨덴",
        switzerland: "스위스",
        taiwan_China: "대만",
        tajikistan: "타지키스탄",
        tanzania: "탄자니아",
        thailand: "태국",
        turkey: "터키",
        turkmenistan: "투르크메니스탄",
        ukraine: "영국",
        united_Arab_Emirates: "미국",
        united_Kingdom: "우즈베키스탄",
        united_States: "베네수엘라",
        uzbekistan: "베트남",
        venezuela: "아프가니스탄",
        vietnam: "베트남",
        afghanistan: "아프가니스탄",
        angola: "앙골라",
        azerbaijan: "아제르바이잔",
        bangladesh: "방글라데시",
        belarus: "벨라루스",
        belize: "벨리즈",
        benin: "베냉",
        bhutan: "부탄",
        bolivia: "볼리비아",
        botswana: "보츠와나",
        british_Virgin_Islands: "영국령 버진 아일랜드",
        burkina_Faso: "부르키나파소",
        burundi: "부룬디",
        cape_Verde: "카보베르데",
        cayman_Islands: "케이맨 제도",
        central_African_Republic: "중앙아프리카",
        chad: "차드",
        comoros: "코모로",
        the_Republic_of_the_Congo: "콩고 (부)",
        democratic_Republic_of_the_Congo: "콩고 (김)",
        djibouti: "지부티",
        ecuador: "에콰도르",
        el_Salvador: "엘살바도르",
        equatorial_Guinea: "적도 기니",
        eritrea: "에리트레아",
        fiji: "피지",
        gabon: "가봉",
        gambia: "감비아",
        greenland: "그린란드",
        guatemala: "과테말라",
        guinea: "기니",
        haiti: "아이티",
        isle_of_Man: "맨 섬",
        cote_d_Ivoire: "코트디부아르",
        jamaica: "자메이카",
        jordan: "요르단",
        lebanon: "레바논",
        lesotho: "레소토",
        liberia: "라이베리아",
        libya: "리비아",
        madagascar: "마다가스카르",
        malawi: "말라위",
        maldives: "몰디브",
        mali: "말리",
        mauritania: "모리타니",
        mauritius: "모리셔스",
        mozambique: "모잠비크",
        namibia: "나미비아",
        nicaragua: "니카라과",
        republic_of_Niger: "니제르",
        north_Korea: "조선",
        reunion: "레위니옹",
        san_Marino: "산마리노",
        senegal: "세네갈",
        sierra_Leone: "시에라리온",
        somalia: "소말리아",
        sudan: "수단",
        suriname: "수리남",
        eswatini: "스와질란드",
        syria: "시리아",
        togo: "토고",
        tonga: "통가",
        tunisia: "튀니지",
        united_States_Virgin_Islands: "미국령 버진 아일랜드",
        uganda: "우간다",
        uruguay: "우루과이",
        vatican: "바티칸",
        yemen: "예멘",
        yugoslavia: "유고슬라비아",
        zambia: "잠비아",
        zimbabwe: "짐바브웨",
        china: "중국",
      }
};
