import {getSystemParam, getDomain} from '@/api/global'

const state = {
  setting: '',
  domain: '',
  country: '',
}

const mutations = {
  SET_SETTING: (state, setting) => {
    state.setting = setting
  },
  SET_DOMAIN: (state, domain) => {
    state.domain = domain
  },
  SET_COUNTRY: (state, country) => {
    state.country = country
  }
}

const actions = {

  setSetting({commit}) {
    // commit('TOGGLE_DEVICE', device)
    return new Promise((resolve, reject) => {
      getSystemParam().then(response => {
        var params = {};
        if (response.Data.content && response.Data.content.length > 0) {
          for (var i in response.Data.content) {
            const name = response.Data.content[i].alias;
            // var obj = {}
            params[name] = response.Data.content[i].dataset
            // params.push(obj)
          }
        }
        commit('SET_SETTING', params)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  setDomain({commit}) {
    return new Promise((resolve, reject) => {
      commit('SET_DOMAIN', "111")
      resolve()
    })
  }

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

